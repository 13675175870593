<template lang="pug">
  .course-photo
    base-input-file(
      ref="baseInput"
      :loading="loading"
      :disabled="disabled"
      @input="inputFile"
    )
      template(#activator)
        slot
          v-btn(
            color="primary"
            outlined
            small
            :disabled="disabled"
          ).cd-actions-item Upload course photo
</template>

<script>
import CourseService from '@/app/admin/models/courseService'
import api from '@/services/api/api-courses'
import errorsMixin from '@/mixins/errors.mixin';

export default {
  name: 'CoursePhoto',

  mixins: [errorsMixin],

  props: {
    courseID: { type: Number, required: true },
    hasActiveItems: { type: Boolean, default: false },
    disabled: Boolean
  },

  computed: {

  },

  data: () => ({
    loading: false,
    hasPhoto: null,
    courseSvc: new CourseService(api),
  }),

  methods: {
    async inputFile(value) {
      try {
        this.loading = true
        let res = await this.courseSvc.uploadCoursePhoto(this.courseID, value);
        if (res && res.course_photo) this.hasPhoto = res.course_photo
        this.clear()
        this.$notify({ text: 'Successfully uploaded', type: 'success' })
      } catch (error) {
        console.log(error);
        this.processError(error);
      }

      setTimeout(() => {
        this.loading = false
      }, 500)
    },

    clear() {
      this.$refs.baseInput.clear()
    }
  },

  components: {
    baseInputFile: () => import('@/components/global/actions/BaseInputFile.vue')
  }
}
</script>

<style lang="scss" scoped>
.course-photo {
  @include media(">=phone") {
    display: flex;
    flex-direction: row;
  }
}

</style>